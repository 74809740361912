import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

import Modal from "../components/modal";

import "./../scss/contact.scss";

const IndexPage = () => (
  <Layout>
    <SEO title="CONTACT" keywords={[`gatsby`, `application`, `react`]} />
    <Modal className="contact">
      <h2>CONTACT</h2>
      <h3>PRESS INQUIRIES</h3>
      <a href="mailto:press@jeremyscott.com">press@jeremyscott.com</a>
    </Modal>
  </Layout>
);

export default IndexPage;
